import email from './email.svg';
import './App.css';

function App() {
  const handleClick = event => {
    window.open('mailto:info@plentykommunikation.se');
  }
  return (
    <div className='Container'>
      <div className="App">
        <header className="App-header"> {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <h1>
            Plenty... and then some!
          </h1>
          <span>
            Plenty Kommunikation är konsulter inom finansiell kommunikation, års- och hållbarhetsredovisning. I vårt nätverk finns erfarna projektledare, skribenter, formgivare, produktionsledare och fotografer som kan hjälpa dig att nå ditt företags intressenter på bästa sätt.        </span>
           <div onClick={ handleClick }><img src={email} className="App-logo" alt="logo"/>
           </div> 
                    </header>     
         
      </div>
    </div>

  );
}

export default App;
